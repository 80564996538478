@import './themes';

@mixin nb-overrides() {
  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

    nb-icon {
      right: 0.41rem !important;
    }
  }
  
  nb-layout-header {
    background-color: transparent;
  }

  nav {
    background: rgba(255, 255, 255, 0.28);
    border-radius: 0px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(12.8px);
    -webkit-backdrop-filter: blur(12.8px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
}
